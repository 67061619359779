import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          options:"Many options to explore Dugi Otok",
          enquiry: "Make an enquiry",
          routes:"We have many custom routes",
          email2:"Send an email",
          form: "Contact form",
          name:"Name",
          title:"Your title",
          email:"Email",
          message:"Your message",
          soon:"We will get back to you as soon as possible!",
          send: "Send an email",
          send2: "Send",
          emailSent:"Your email has been sent. Thank you!",
          home:"Home",
          golubinka: "Golubinka cave",
          boat:"Boat Tours",
          activities: "Activities",
          transfers:"Transfers",
          contact:"Contact",
          gallery: "Gallery",
kayak: "Kayak rentals",
kayak2:"Self-guided tours",
duration:"DURATION",
distance:"DISTANCE",
difficulty:"DIFFICULTY",
departure:"DEPARTURE",
departure1:"From 8:30AM to 11AM",
easyMod:"Easy/Moderate",
easy:"Easy",
hard:"Moderate/Hard",
distance4:"21 kilometers",
duration4:"2 Days",
duration1:"Approximately from 2 hours to 10 hrs",
distance1:"14 km round trip or 7 km one direction",
duration2:"From 2 hours to 8 hours",
distance2:"Around 10 kilometers",
duration3:"From 3 hours to 6 hrs",
distance3:"12 km round trip or 6 km one direction",
usefulInfo:"USEFUL INFO",
pricing:"PRICING",
booking:"BOOKING",
including:"What is included?",
what:"What to bring?",
safety:"Safety",
meeting:"Meeting point",
weather:"Weather",
queries:"For all requests/bookings please send an ",
swimsuit:"Swimwear, towel, dry clothes to change into, sunscreen and hat",
weather2:"We closely monitor local weather forecasts and will inform you of what kind of weather you can expect.​",
weather3:"In the case of bad weather, we will try to reschedule your adventure or give you alternative locations.",
safety2:"Previous SUP/Kayak experience is desirable but not essential. Julien will provide a detailed itinerary and all of the dos and don'ts for a safe and enjoyable experience",
safety3:"Previous Kayak experience and good fitness level is a must. Julien will provide a detailed itinerary and all of the dos and don'ts for a safe and enjoyable experience.",
pickupNo:"without pick-up or drop-off",
pickupYes:"with pick-up and drop-off",
person:"per person",
allEquipment:"- All equipment - Kayak/SUP, paddle, snorkling gear, parasol, dry bag and safety vest if needed.",
allEquipment2:"- All equipment - Kayak, paddle, dry bag and safety vest if needed.",
arranged:"- Pick up and drop-off can be arranged",
pickUp:"- Pick up and drop-off from Brbinj/Bozava harbour",
lunch:"- Pack lunch with 750ml water per person",
morePack:"more for pack lunch",
meeting1:"Veli zal beach, but pick-up is also a possibility as well as other locations",
meeting2:"Dragove bay but pick-up and drop-off can be arranged",
lunch2:"- Pack lunch with 750ml water per person for 2 days",
breakfast:"- Breakfast and coffee on day 2",
tent:"with tent option",
between:"Between",
studio:"for studio apartment with swimming pool based on the time of the year",
text1:"We rent both oceans and crystal kayaks on Veli Zal and Saharun beach everyday. We have several rates for hourly and day long rentals. Call us on 0957116161 or 0977145168. All of our prices are per kayak (two seaters only).",
trail1Text:"Depart from Veli Zal and head south to Golubinka cave. With 4 private beaches on the way down, you will have sufficient places to stop, sunbathe and relax.If you're lucky you might even get to see a dolphin pack on your way. Golubinka Cave is a very popular sea cave visited by many each year. One option is for you to do a round trip or we can come and pick you up. It is a day rental and all equipment is due back by 6PM",
trail2Text:"Start your journey from Dragove village's own bay on the east coast of Dugi Otok and head North to visit, not just the coastline, but also the two bunkers built during the Tito era of the ex Yugoslavia. Purpose built for hiding interception ships, you will also find a few beaches, secluded olive groves, small islands and you could even stop in Bozava for lunch in one of the local restaurants It is a day rental and all equipment is due back by 6PM",
trail3Text:"Travel from Veli Zal beach to Saharun, one of Croatia's most famous beaches. On the way you'll find many spots along the rocky coastline to stop, and snorkel, including one of Dugi Otok's most famous dive spots in Lopata. This trail will be appealing to visitors who come from the city for a one-day trip. Take the opportunity to visit two of the most beautiful beaches on the island. It is a day rental and all equipment is due back by 6PM",
trail4Text:"For the more adventurous, why not combine the two most sought after attractions of Dugi Otok, plus its most stunning beaches and a huge chunk of its coastline. You will be given the option to sleep au naturel in a designated spot, with a provided tent and all necessary equipment, or in our accomodation in Dragove village (based on availability).",
day1:"Day 1",
day2:"Day 2",
day1Text:"Depart from Veli Rat and travel to St Michelle shipwreck. Then head south to Saharun beach passing by stunning high cliff scenery, as well as the very popular snorkeling spot of Lopata. You will then be required to do a further 6 km to reach your end point of the day, near Veli Zal beach. Your resting place for the night to be agreed upon prior to departure. ",
day2Text:"Head south to Golubinka cave at your chosen time. Pass by two of the most amazing beaches only accessibe by sea, and enter the cave for around 1pm. You are then only a short distance from your pick up point and end of journey. ",
tours:"ONE-DAY TOURS",
text2:"The most popular coastline spots are situated in the northwest of the island. Whether on or under the surface of the water. For many it is the best to have a guide but for those of you out there wishing to explore on your own, this is your ticket to freedom. We offer several routes with varying levels of difficulty",
adventure:"Adventure starts here...",
text3:"The bunker trial: Start your journey from Dragove village's own bay on the east coast of Dugi otok, and head north to visit, not just the coastline, but also the three bunkers built during the Tito era of the ex Yougoslavia. Purpose built for hiding interceptions ships. You will find many beaches, secluded olive groves and you could even stop in Bozava for lunch, in one of the various local restaurants.50 euros",
twoDays:"TWO DAYS TOURS",
trail1: "Veli Zal beach to Golubinka cave",
trail2: "Crystal Experience",
trail3:"The Lagoon's Trail",
trail4:"Veli Rat (lighthouse) to Golubinka cave",
twoDays2:"Tours that include accommodation",
text5:"The sunset trail : Depart from Veli Zal beach and head all the way up the north tip of the island to Veli Rat and its famous lighthouse.There, you can enjoy the most beautiful sunset in the Adriatic, to conclude a fantastic day on the sea. Pick up is included for this trail due to its length and the timming of it. 80 euros",
note:"PLEASE NOTE:",
text6:"It is possible to do only one way for trails 1,2 and 3 and to get a pickup at an agreed time for an extra cost.",
text7:" * All activities are weather dependant and best started in the morning",
bike:"Bike rentals",
daily:"Daily rentals",
text8:" We have traditional bikes available for rent. Those can be picked up at our home location in Dragove village, or depending on where you are, we can bring them to you. Our standard rate is 10 euros per day, but for any rentals of  ",
text9:"four days or more, the rate falls to 8 euros a day. Call us on 0957116161 or 09777145168.",
sunset:"Sunset on Mezanj island",
enjoy:"Enjoy a beautiful sunset on Veli Zal",
text10:"Mezanj island is directly opposite Veli Zal beach our base of operations. Why not rent a kayak or a SUP board and find a spot either on Mezanj or on the surrounding waters. All departures should be done at least 45 minutes before sunset. As per most water activities, this is weather dependant",
glide:"Rent E-scooter",

text11:"Explore the island with minimum impact on the environment. We rent electric scooters for 2 people, helmets included, and if necessary we also offer a solar charger for longer trips.",
lost:"Lost in the sea",
diver:"Rent a diver for your lost belongings",
diver2:"Have you lost something of value ? Do you have a location and it is near the shore ? Julien is an experienced diver with a high ratio of recovery.",
sup:"Sup rentals",
text12:"As well as kayaks we also rent SUP boards. Prices are also for hourly and daily rates, for more information, call us on 0957116161 or 09577145168. Rate are per SUP boards.",
transfers1:"Transfers From Brbinj To Veli Zal",
more:"See more...",
transfers2:"Transfers From Brbinj To Saharun",
transfers3:"Transfers From Brbinj To Veli Rat",
text13:"Veli Zal is the ultimate non commercialized beach, and is highly recommended for those wanting to reconnect with true unspoiled seaside. We offer private transfers from Brbinj and that is our most sought after route. Veli Zal is very famous  on the island and features white sand in the sea and beautiful white pebbles outside. We also rent sun loungers and umbrellas so you can comfortably enjoy your day at the beach. Our transparent canoes and ocean kayaks are there if you feel adventurous enough for one of our many safaris,  or if you just want to break away from the beach, and head to the small island called Mezanj directly opposite. The crystal clear water will make you want  to go snorkeling and that is the perfect opportunity to rent our crystal canoes, as you can paddle and stare at the beautiful sea bed. We also rent aquaglide equipment for windy days, and the near by beach bar provides beach goers with the perfect venue to enjoy a bite and a drink.",
top:"BACK TO TOP",
text14:" Veli Rat has arguably the best campsite on Dugi otok, and is the nearest departing point for those wishing to go snorkelling around the famous St Michelle shipwreck. Veli Rat is also famous for its lighthouse, the tallest in the Adriatic and is truly worth a visit. Add the two very cute pebble beaches beside the lighthouse and the spot's reputation as the best sunset this side of Italy and you have all of the best reasons in the world to visit.",
transfers4:"Bozava or Brbinj to Saharun beach",
transfers5:"Brbinj to Veli Rat",
transfers6:"Brbinj to Veli Zal",
saharun:" Saharun is by far the most sought after destination  on Dugi Otok. It has aquired the reputation as one of Croatia's most beautiful beaches. It is unique in that it is shallow and sandy. Nestled in a beautiful bay, Saharun is really a couple's dream, but beware the crowds !",
accommodation: "Accommodation",
acc1:"We offer apartments with a heated pool and jacuzzi with a beautiful view of the sea",
acc2:"Special offers for all visitors in April, May, June, September and October",
acc3:"Discounts available if you send us an email! Check the link to see",
acc4:"apartments!"


        }
      },
      hr: {
        translation: {
          options:"Puno opcija za otkrivanje Dugog Otoka",
          enquiry: "Pošaljite upit",
          routes:"Imamo mnogo raznih opcija",
          email2:"Pošaljite e-mail",
          form: "Kontakt obrazac",
          name:"Ime",
          title:"Naslov",
          email:"E-mail",
          message:"Vaša poruka",
          soon:"Javit ćemo vam se što je prije moguće!",
          send: "Pošaljite  e-mail",
          send2: "Pošalji",
          emailSent:"Vaš e-mail je poslan. Hvala vam!",
          home:"Home",
          golubinka: "Golubinka špilja",
          boat:"Izleti brodom",
          activities: "Aktivnosti",
          transfers:"Transferi",
          contact:"Kontakt",
          gallery: "Galerija",
kayak: "Iznajmljivanje kajaka",
kayak2:"Samostalne ture",
duration:"TRAJANJE",
distance:"UDALJENOST",
difficulty:"LEVEL",
departure:"POLAZAK",
departure1:"Od 8:30AM do 11AM",
easyMod:"Lagano/Umjereno",
easy:"Lagano",
hard:"Umjereno/Teško",
distance4:"21 kilometar",
duration4:"2 dana",
duration1:"Približno od 2 sata do 10 sati",
distance1:"14 km povratno ili 7 km jedan pravac",
duration2:"Od 2 sata do 8 sati",
distance2:"Oko 10 kilometara",
duration3:"Od 3 sata do 6 sati",
distance3:"12 km povratno ili 6 km u jednom smjeru",
usefulInfo:"KORISNI INFO",
pricing:"CJENIK",
booking:"REZERVACIJA",
queries:"Za sve upite i rezervacije molim pošaljite ",
including:"Što je uključeno?",
what:"Što ponijeti?",
safety:"Sigurnost",
meeting:"Gdje se vidimo?",
weather:"Vrijeme",
swimsuit:"Kupaći kostim, ručnik, suha odjeća za presvlačenje, krema za sunčanje i šešir",
weather2:"Pomno pratimo lokalne vremenske prognoze i obavijestit ćemo vas o vremenu koje možete očekivati.​​",
weather3:" U slučaju lošeg vremena, pokušat ćemo pomaknuti vašu avanturu ili vam dati alternativne lokacije.",
safety2:"Prethodno SUP/Kayak iskustvo je poželjno, ali nije neophodno. Julien će dati detaljan plan puta i sve što treba i što ne treba raditi za sigurno i ugodno iskustvo",
safety3:"Prethodno iskustvo sa kajakom i dobra razina kondicije su obavezni. Julien će dati detaljan plan puta i sve što treba i što ne treba raditi za sigurno i ugodno iskustvo.",
pickupNo:"bez preuzimanja ili vraćanja u luku",
pickupYes:"sa preuzimanjem i iskrcajem do luke",
person:"po osobi",
allEquipment:"- Sva oprema - kajak/SUP, veslo, oprema za ronjenje, suncobran, suha torba i sigurnosni prsluk po potrebi",
allEquipment2:"- Sva oprema - kajak, veslo, suha torba i sigurnosni prsluk po potrebi",
arranged:"- Moguće je dogovoriti preuzimanje i iskrcaj do luke",
pickUp:"- preuzimanje i iskrcaj iz luke Brbinj/Božava",
lunch:"- Lunch paket sa 750 ml vode po osobi",
morePack:"više za lunch paket",
meeting1:"Plaža Veli žal, ali možemo i doći po Vas kao i dočekati Vas na drugim lokacijama",
meeting2:"Uvala Dragove, ali prijevoz i iskrcaj se mogu dogovoriti",
lunch2:"- Lunch paket sa 750 ml vode po osobi za 2 dana",
breakfast:"- Doručak i kava 2. dan",
tent:"s opcijom šatora",
between:"Između",
studio:"za studio apartman s bazenom prema godišnjem dobu",
text1:"Na Velom Žalu i Saharunu svakodnevno iznajmljujemo obične i kristalne kajake. Imamo nekoliko cijena za najam po satu i danu. Nazovite nas na 0957116161 ili 0977145168. Sve naše cijene su po kajaku, ne po osobi (samo dvosjed).",
trail1Text:"Krenite od Velog Žala prema jugu do špilje Golubinka. Uz 4 privatne plaže na putu prema dolje, imat ćete dovoljno mjesta za zaustavljanje, sunčanje i opuštanje. Ako budete imali sreće, možda ćete usput vidjeti i čopor dupina. Špilja Golubinka vrlo je popularna morska špilja koju svake godine posjećuju mnogi. Jedna od opcija je da napravite povratno putovanje ili mi možemo doći po vas. To je dnevni najam i svu opremu treba vratiti do 18:00",
trail2Text:"Započnite svoje putovanje iz uvale sela Dragove na istočnoj obali Dugog otoka i krenite prema sjeveru kako biste posjetili, ne samo obalu, već i dva bunkera izgrađena tijekom Titove ere bivše Jugoslavije. Namjenski izgrađen za skrivanje presretačkih brodova, također ćete pronaći nekoliko plaža, osamljene maslinike, male otočiće, a možete čak i stati u Božavi na ručak u jednom od lokalnih restorana. To je dnevni najam i svu opremu treba vratiti do 18:00.",
trail3Text:"Putujte od plaže Veli Žal do Saharuna, jedne od najpoznatijih plaža u Hrvatskoj. Na putu ćete pronaći mnoga mjesta duž stjenovite obale gdje ćete se zaustaviti i roniti, uključujući jedno od najpoznatijih mjesta za ronjenje na Dugom Otoku u Lopati. Ova će staza biti privlačna posjetiteljima koji dolaze iz grada na jednodnevni izlet. Iskoristite priliku i posjetite dvije od najljepših plaža na otoku. To je dnevni najam i svu opremu treba vratiti do 18:00",
trail4Text:"Za one pustolovnije, zašto ne spojiti dvije najtraženije atrakcije Dugog otoka, plus njegove najljepše plaže i veliki dio njegove obale. Imat ćete mogućnost spavanja u prirodi na za to predviđenom mjestu, s osiguranim šatorom i svom potrebnom opremom ili u našem smještaju u selu Dragove (prema raspoloživosti).",
day1:"Dan 1",
day2:"Dan 2",
day1Text:"Polazak iz Velog Rata i vožnja do olupine St Michelle. Zatim krenite prema jugu do plaže Saharun prolazeći pored zapanjujućeg krajolika visokih litica kao i vrlo popularnog mjesta za ronjenje Lopata. Zatim ćete morati putovati još 6 km do vaše krajnje točke dana, u blizini plaže Veli Žal. Mjesto odmora za noćenje po dogovoru prije polaska.",
day2Text:"Krenite prema jugu do špilje Golubinka u odabrano vrijeme. Prođite pored dvije najčudesnije plaže do kojih se može doći samo s mora i uđite u špilju oko 13 sati. Tada ste samo na maloj udaljenosti od mjesta preuzimanja i kraja putovanja.",
tours:"JEDNODNEVNE TURE",
text2:"Najpopularnije atrakcije na obali nalaze se na sjeverozapadu otoka. Bilo na površini vode ili ispod nje. Za mnoge je najbolje imati vodiča, ali za one od vas koji žele istraživati ​​sami, ovo je vaša karta za slobodu. Nudimo nekoliko ruta s različitim razinama težine ",
adventure:"Avantura počinje ovdje...",
text3:"Titov bunker: Započnite svoje putovanje iz uvale sela Dragove na istočnoj obali Dugog otoka i krenite prema sjeveru kako biste posjetili, ne samo obalu, već i tri bunkera izgrađena tijekom Titove ere bivše Jugoslavije. Namjenski izgrađen za skrivanje brodova presretača. Naći ćete mnoge plaže, osamljene maslinike, a možete čak i stati u Božavi na ručak, u jednom od raznih lokalnih restorana.Cijena 50 eura",
twoDays:"DVODNEVNE TURE",
text4:"Staza plave lagune : Putujte od plaže Veli Žal do Saharuna, jedne od najpoznatijih plaža u Hrvatskoj. Na putu ćete pronaći mnoga mjesta duž stjenovite obale za zaustavljanje i ronjenje, uključujući jedno od najpoznatijih mjesta za ronjenje na Dugom otoku u Lopati. Cijena 50 eura",
twoDays2:"Ture koje uključuju smještaj",
trail1: "Plaža Veli Žal do špilje Golubinka",
trail2: "Kristalno Iskustvo",
trail3:"Staza Lagune",
trail4:"Veli rat (svjetionik) do špilje Golubinka",
text5:"Zalazak sunca: Krenite s plaže Veli Žal i krenite sve do sjevernog vrha otoka do Velog Rata i njegovog poznatog svjetionika. Tamo možete uživati ​​u najljepšem zalasku sunca na Jadranu i zaključiti fantastičan dan na moru. . Transfer je uključen za ovu stazu zbog njezine duljine i vremena. 80 eura",
note:"MOLIMO IMAJTE NA UMU:",
text6:"Za opcije 1,2 i 3 moguće je ići samo u jednom smjeru te uz nadoplatu doći u dogovoreno vrijeme.",
text7:" * Sve aktivnosti ovise o vremenskim prilikama i najbolje ih je započeti ujutro",
bike:"Iznajmljivanje bicikala",
daily:"Dnevni najam",
text8:" Imamo tradicionalne bicikle dostupne za iznajmljivanje. Možete ih preuzeti kod nas u selu Dragove ili vam ih možemo donijeti, ovisno o tome gdje se nalazite. Naša standardna cijena je 10 eura po danu, ali za bilo koji najam",
text9:"četiri ili više dana, stopa pada na 8 eura dnevno. Nazovite nas na 0957116161 ili 09777145168.",
sunset:"Zalazak sunca na otoku Mežnju",
enjoy:"Uživajte u prekrasnom zalasku sunca na Velom Žalu",
text10:"Otok Mežanj je točno nasuprot plaže Veli Žal naše baze poslovanja. Zašto ne unajmite kajak ili SUP dasku i pronađete mjesto na Meznju ili na okolnim vodama. Svi polasci trebaju biti početi najmanje 45 minuta prije zalaska sunca. Kao i kod većine vodenih aktivnosti, ovo ovisi o vremenu",
glide:"Najam električnog skutera",

text11:"Istražite otok uz minimalan utjecaj na okoliš. Iznajmljujemo električne skutere za 2 osobe, kacige uključene, a po potrebi nudimo i solarni punjač za duža putovanja.",
lost:"Izgubljeno u moru",
diver:"Iznajmite ronioca za izgubljene stvari",
diver2:"Jeste li izgubili nešto vrijedno? Imate li lokaciju i blizu je obale? Julien je iskusan ronilac s visokim omjerom pronalazaka.",
sup:"Najam supova",
text12:"Osim kajaka iznajmljujemo i SUP daske. Cijene su također za sat i dan, za više informacija nazovite nas na 0957116161 ili 09577145168 Cijene su po SUP dasci.",
transfers1:"Transfer od Brbinja do Velog Žala",
more:"Vidi više...",
transfers2:"Transfer od Brbinja do Saharuna",
transfers3:"Transfer od Brbinja do Velog Rata",
text13:"Veli Žal je ultimativna nekomercijalizirana plaža i toplo se preporučuje onima koji se žele ponovno povezati s pravom netaknutom morskom obalom. Nudimo privatne transfere iz Brbinja i to je naša najtraženija ruta. Veli Žal je vrlo poznat na otoku i odlikuje se bijelim pijeskom u moru i prekrasnim bijelim šljunkom izvana. Također iznajmljujemo ležaljke i suncobrane kako biste udobno uživali u danu na plaži. Naši prozirni i obični kajaci tu su ako se osjećate dovoljno avanturistički nastrojenim za jedan od naših brojnih safarija ili ako se samo želite odvojiti od plaže i uputiti se na otočić Mežanj koji se nalazi točno nasuprot. Kristalno čista voda natjerat će vas da poželite roniti, a to je savršena prilika da unajmite naše kristalne kanue, jer možete veslati i gledati u prekrasno morsko dno. Također iznajmljujemo opremu za aquaglide za vjetrovite dane, a obližnji bar na plaži pruža posjetiteljima plaže savršeno mjesto za uživanje u zalogaju i piću.",
top:"VRATITE SE NA VRH",
text14:" Veli Rat ima uvjerljivo najbolji kamp na Dugom otoku, te je najbliža polazna točka za one koji žele roniti oko poznate olupine St Michelle. Veli Rat poznat je i po svom svjetioniku, najvišem na Jadranu i zaista ga vrijedi posjetiti. Dodajte dvije vrlo slatke šljunčane plaže pored svjetionika i reputaciju mjesta kao najboljeg zalaska sunca s ove strane Italije i imat ćete sve najbolje razloge na svijetu da ga posjetite.",
transfers4:"Božava ili Brbinj do plaže Saharun",
transfers5:"Brbinj do Velog Rata",
transfers6:"Brbinj do Velog Žala",
saharun:"Saharun je daleko najtraženija destinacija na Dugom otoku. Stekla je reputaciju jedne od najljepših hrvatskih plaža. Jedinstvena je po tome što je plitka i pjeskovita. Ugniježđen u prekrasnom zaljevu, Saharun je doista san za par, ali pripazite na gužve!",
accommodation: "Smještaj",
acc1:"Nudimo apartmane s grijanim bazenom i jacuzzijem s prekrasnim pogledom na more",
acc2:"Posebne ponude za sve posjetitelje u travnju, svibnju, lipnju, rujnu i listopadu",
acc3:"Nudimo popuste, samo nam pošaljite e-mail! Apartmane možete pogledati ",
acc4:"ovdje!"


        }
      },
      it: {
        translation: {
          options:"Molte opzioni per esplorare Dugi Otok",
          enquiry: "Fai una richiesta",
          routes:"Abbiamo molti percorsi personalizzati",
          email2:"Invia un'e-mail",
          form: "Modulo di Contatto",
          name:"Nome",
          title:"Titolo",
          email:"E-mail",
          message:"Il tuo messaggio",
          soon:"Ti risponderemo il prima possibile!",
          send: "Invia un'e-mail",
          send2: "Invii",
          emailSent:"La tua email è stata inviata. Grazie!",
          home:"Pagina iniziale",
          golubinka: "Golubinka cave",
          boat:"Gite in barca",
          activities: "Attività",
          transfers:"Trasferimenti",
          contact:"Contatto",
          gallery: "Galleria",
kayak: "Noleggio kayak",
kayak2:"Tour autoguidato",
duration:"DURATA",
distance:"DISTANZA",
difficulty:"DIFFICOLTÀ",
departure:"PARTENZA",
departure1:"Dalle 8:30 alle 11:00",
easyMod:"Facile/Moderato",
easy:"Facile",
hard:"Moderato/Difficile",
distance4:"21 chilometri",
duration4:"2 Giorni",
duration1:"Circa da 2 ore a 10 ore",
distance1:"14 km andata e ritorno o 7 km sola andata",
duration2:"Da 2 ore a 8 ore",
distance2:"Circa 10 chilometri",
duration3:"Da 3 ore a 6 ore",
distance3:"12 km andata e ritorno o 6 km sola andata",
usefulInfo:"INFO UTILI",
pricing:"PREZZI",
booking:"PRENOTAZIONE",
queries:"Per tutte le richieste/prenotazioni inviare un ",
including:"Cosa è incluso?",
what:"Cosa portare?",
safety:"Sicurezza",
meeting:"Punto d'incontro",
weather:"Meteo",
swimsuit:"Costume da bagno, asciugamano, vestiti asciutti per cambiarsi, crema solare e cappello.",
weather2:"Monitoriamo attentamente le previsioni meteorologiche locali e ti informeremo sul tipo di tempo che puoi aspettarti.​",
weather3:" In caso di maltempo, proveremo a riprogrammare la tua avventura o a darti luoghi alternativi.",
safety2:"Una precedente esperienza di SUP/Kayak è auspicabile ma non essenziale. Julien fornirà un itinerario dettagliato e tutte le cose da fare e da non fare per un'esperienza sicura e divertente.",
safety3:"Una precedente esperienza in kayak e un buon livello di forma fisica sono indispensabili. Julien fornirà un itinerario dettagliato e tutte le cose da fare e da non fare per un'esperienza sicura e divertente.",

pickupNo:"senza ritiro o riconsegna al porto",
pickupYes:"con ritiro e riconsegna al porto",
person:"a persona",
allEquipment:"- Tutta l'attrezzatura: kayak/SUP, pagaia, attrezzatura per lo snorkeling, ombrellone, borsa impermeabile e giubbotto di sicurezza se necessario",
allEquipment2:"- Tutta l'attrezzatura: kayak, pagaia, borsa impermeabile e giubbotto di sicurezza se necessario",
arranged:"- È possibile organizzare il ritiro e la riconsegna al porto",
pickUp:"- Prelievo e rientro dal porto di Brbinj/Bozava",
lunch:"- Pranzo al sacco con 750 ml di acqua a persona",
morePack:"altro per il pranzo al sacco",
meeting1:"Spiaggia Veli zal, ma possiamo anche venirvi a prendere e incontrarvi in ​​altri luoghi",
meeting2:"Baia di Dragove ma è possibile organizzare il ritiro e la riconsegna",
lunch2:"- Pack lunch with 750ml water per person for 2 days",
breakfast:"- Breakfast and coffee on day 2",
tent:"with tent option",
between:"Between",
studio:"for studio apartment with swimming pool based on the time of the year",
text1:"Noleggiamo giornalmente sia kayak oceanici che trasparenti su Veli Zal e la spiaggia di Saharun. Abbiamo tariffe diverse per noleggi orari e giornalieri. Chiamaci allo 0957116161 o allo 0977145168. Tutti i nostri prezzi si intendono per kayak (solo due posti).",
trail1Text:"Da Veli Žal dirigetevi a sud verso la grotta Golubinka. Con 4 spiagge private lungo la discesa, avrai molti posti dove fermarti, prendere il sole e rilassarti. Se sei fortunato, potresti anche vedere un branco di delfini lungo la strada. La Grotta Golubinka è una grotta marina molto popolare che viene visitata da molti ogni anno. Un'opzione è fare un viaggio di ritorno oppure possiamo venirti a prendere. Si tratta di un noleggio giornaliero e tutta l'attrezzatura deve essere restituita entro le 18:00",
trail2Text:"Inizia il tuo viaggio dalla baia del villaggio di Dragove, sulla costa orientale di Dugi Otok, e dirigiti a nord per visitare, non solo la costa, ma anche i due bunker costruiti durante l'era Tito dell'ex Jugoslavia. Costruito appositamente per nascondere le navi di intercettazione, troverai anche alcune spiagge, uliveti appartati, piccole isole e potresti anche fermarti a Bozava per pranzo in uno dei ristoranti locali. È un noleggio giornaliero e tutta l'attrezzatura deve essere restituita entro le 18:00",
trail3Text:"Viaggia dalla spiaggia di Veli Zal a Saharun, una delle spiagge più famose della Croazia. Lungo la strada troverai molti punti lungo la costa rocciosa dove fermarti e fare snorkeling, incluso uno dei punti di immersione più famosi di Dugi Otok a Lopata. Questo percorso attirerà i visitatori che provengono dalla città per una gita di un giorno. Cogli l'occasione per visitare due delle spiagge più belle dell'isola. Si tratta di un noleggio giornaliero e tutta l'attrezzatura deve essere restituita entro le 18:00",
trail4Text:"Per i più avventurosi, perché non combinare le due attrazioni più ambite di Dugi Otok, oltre alle sue spiagge più belle e un'enorme porzione della sua costa. Ti verrà data la possibilità di dormire nella natura in un luogo designato, con una tenda fornita e tutta l'attrezzatura necessaria, oppure nel nostro alloggio nel villaggio di Dragove (in base alla disponibilità).",
tours:"TOUR DI UN GIORNO",
day1:"Giorno 1",
day2:"Giorno 2",
day1Text:"Partenza da Veli Rat e viaggio verso il relitto di St Michelle. Quindi dirigiti a sud verso la spiaggia di Saharun attraversando paesaggi mozzafiato di imponenti scogliere e il famosissimo sito di immersione di Lopata. Poi dovrai percorrere altri 6 km fino al punto finale della giornata, vicino alla spiaggia di Veli Žal. Luogo di riposo per la notte previo accordo prima della partenza.",
day2Text:"Dirigiti a sud verso la grotta Golubinka all'orario prescelto. Passa accanto a due delle spiagge più belle accessibili solo via mare ed entra nella grotta intorno alle 13:00. Ti troverai quindi a breve distanza dal punto di raccolta e dalla fine del viaggio.",
text2:"Le attrazioni costiere più popolari si trovano nel nord-ovest dell'isola. Sia sopra che sotto la superficie dell'acqua. Per molti, avere una guida è la cosa migliore, ma per quelli di voi che vogliono esplorare da soli, questo è il vostro biglietto per la libertà. Offriamo diversi percorsi con diversi livelli di difficoltà",
adventure:"L'avventura inizia qui...",
text3:"Bunker di Tito: inizia il tuo viaggio dalla baia del villaggio di Dragove sulla costa orientale di Dugi otok e dirigiti a nord per visitare, oltre alla costa, anche i tre bunker costruiti durante l'era di Tito nell'ex Jugoslavia. Sono stati costruiti per nascondere le navi. Troverai molte spiagge, uliveti appartati e potresti anche fermarti a Bozava per il pranzo, in uno dei vari ristoranti locali.50 euro",
twoDays:"TOUR DI DUE GIORNI",
text4:"La Laguna Blu: Viaggia dalla spiaggia di Veli Zal a Saharun, una delle spiagge più famose della Croazia. Lungo il percorso troverai molti punti lungo la costa rocciosa dove fermarti e fare snorkeling, incluso uno dei punti di immersione più popolari su Dugi otok a Lopata. 50 euro",
twoDays2:"Tour che includono l'alloggio",
trail1: "Dalla spiaggia Veli Zal alla grotta Golubinka",
trail2: "Esperienza Di Cristallo",
trail3:"Il Sentiero della Laguna",
trail4:"Veli Rat (faro) alla grotta Golubinka",
text5:"Tramonto: Parti dalla spiaggia di Veli Zal e dirigiti verso la punta nord dell'isola fino a Veli Rat e al suo famoso faro. Lì, puoi goderti il ​​tramonto più bello dell'Adriatico, per concludere una fantastica giornata al mare. Il ritiro è incluso per questo percorso a causa della sua lunghezza e tempistica. 80 euro",
note:"ATTENZIONE:",
text6:"È possibile andare solo andata per l'opzione 1, 2 e 3 e ottenere un ritiro a un orario concordato a un costo aggiuntivo.",
text7:" *Tutte le attività dipendono dalle condizioni meteorologiche ed è meglio iniziare al mattino",
bike:"Noleggio biciclette",
daily:"Affitti giornalieri",
text8:"Disponiamo di biciclette tradizionali a noleggio. Questi possono essere ritirati presso la nostra sede nel villaggio di Dragove o, a seconda di dove ti trovi, possiamo portarteli. La nostra tariffa standard è di 10 euro al giorno, ma per eventuali noleggi di ",
text9:"quattro o più giorni, la tariffa scende a 8 euro al giorno. Chiamaci allo 0957116161 o 09777145168.",
sunset:"Tramonto sull'isola di Mezanj",
enjoy:"Goditi uno splendido tramonto su Veli Zal",
text10:"L'isola di Mezanj si trova proprio di fronte alla spiaggia di Veli Zal, la nostra base operativa. Perché non noleggiare un kayak o una tavola da SUP e trovare un posto a Mezanj o nelle acque circostanti. Tutte le partenze devono essere effettuate almeno 45 minuti prima del tramonto. Come con la maggior parte delle attività acquatiche, questo dipende dal tempo",
glide:"Noleggia uno scooter elettrico",

text11:"Esplora l'isola con il minimo impatto sull'ambiente. Noleggiamo scooter elettrici per 2 persone, caschi compresi, e se necessario offriamo anche un caricatore solare per i viaggi più lunghi.",
lost:"Perso nel mare",
diver:"Assumi un subacqueo per i tuoi oggetti smarriti",
diver2:"Hai perso qualcosa di valore? Hai una posizione ed è vicino alla riva? Julien è un subacqueo esperto con un alto tasso di recupero.",
sup:"Noleggio Sup",
text12:"Oltre ai kayak, noleggiamo anche tavole SUP. I prezzi sono anche per un'ora e per un giorno, per maggiori informazioni, chiamaci allo 0957116161 o 09577145168. Le tariffe sono per tavole SUP.",
transfers1:"Trasferimenti ​​da Brbinj a Veli Zal",
more:"Vedi di più...",
transfers2:"Trasferimenti ​​da Brbinj a Saharun",
transfers3:"Trasferimenti ​​da Brbinj a Veli Rat",
text13:"Veli Zal è l'ultima spiaggia non commercializzata ed è altamente raccomandata per coloro che desiderano riconnettersi con il vero mare incontaminato. Offriamo trasferimenti privati ​​da Brbinj e questo è il nostro percorso più cercato. Veli Zal è molto famosa sull'isola e ha sabbia bianca nel mare e bellissimi ciottoli bianchi all'esterno. Noleggiamo anche lettini e ombrelloni per farti sentire a tuo agio godendoti la giornata in spiaggia. Le nostre canoe trasparenti e i kayak oceanici sono lì se ti senti abbastanza avventuroso o se vuoi semplicemente staccarti dalla spiaggia e dirigerti verso la piccola isola chiamata Mezanj proprio di fronte. L'acqua cristallina ti farà venire voglia di fare snorkeling e questa è l'occasione perfetta per noleggiare le nostre canoe di cristallo, così potrai pagaiare e ammirare gli splendidi fondali. Noleggiamo anche attrezzature per acquaglide per le giornate ventose e il vicino bar sulla spiaggia offre agli amanti della tintarella il luogo perfetto per mangiare un boccone e bere qualcosa.",
top:"VAI IN CIMA",
text14:" Veli Rat ha senza dubbio il miglior campeggio di Dugi otok, ed è il punto di partenza più vicino, per coloro che desiderano fare snorkeling intorno al famoso relitto di St Michelle. Veli Rat è famosa anche per il suo faro, il più alto dell'Adriatico e merita davvero una visita. Aggiungete le due graziose spiagge di ciottoli vicino al faro e la reputazione del luogo di avere il miglior tramonto in questa parte d'Italia, e avrete tutti i migliori motivi del mondo per venire a visitarla.",
transfers4:"Bozava o Brbinj fino alla spiaggia di Saharun",
transfers5:"Brbinj a Veli Rat",
transfers6:"Brbinj a Veli Zal",
saharun:" Saharun è di gran lunga la destinazione più ambita di Dugi otok. Ha acquisito una reputazione come una delle spiagge più belle della Croazia. È unico in quanto è poco profondo e sabbioso. Nidificato in una bellissima baia, Saharun è davvero il sogno di una coppia!",
accommodation: "Accommodation",
acc1:"Offriamo appartamenti con piscina riscaldata e jacuzzi con una splendida vista sul mare",
acc2:"Offerte speciali per tutti i visitatori in aprile, maggio, giugno, settembre e ottobre",
acc3:"Sconti disponibili se ci invii un'e-mail! Controlla il link per vedere",
acc4:"appartamenti!"


        }
      },
      de: {
        translation: {
          options:"Viele Möglichkeiten, Dugi Otok zu erkunden",
          enquiry: "Machen Sie eine Anfrage",
          routes:"Wir haben viele benutzerdefinierte Routen",
          email2:"Eine E-Mail senden",
          form: "Kontakt Formular",
          name:"Namen",
          title:"Titel",
          email:"Email",
          message:"Ihre Nachricht",
          soon:"Wir werden uns so schnell wie möglich bei Ihnen melden!",
          send: "Eine E-Mail senden",
          send2: "Eine",
          emailSent:"Ihre E-Mail wurde gesendet. Danke schön!",
          home:"Startseite",
          golubinka: "Golubinka cave",
          boat:"Bootstouren",
          activities: "Aktivitäten",
          transfers:"Überweisungen",
          contact:"Kontakt",
          gallery: "Galerie",
kayak: "Kajakverleih",
kayak2:"Selbstgeführte Tour",
duration:"DAUER",
distance:"DISTANZ",
difficulty:"SCHWIERIGKEIT",
departure:"ABFAHRT",
departure1:"Von 8:30 bis 11:00 Uhr",
easyMod:"Leicht/Mittel",
easy:"Einfach",
hard:"Mäßig/Schwer",
distance4:"21 kilometer",
duration4:"2 Tage",
duration1:"Ungefähr 2 bis 10 Stunden",
distance1:"14 km hin und zurück oder 7 km in eine Richtung",
duration2:"Von 2 Stunden bis 8 Stunden",
distance2:"Etwa 10 Kilometer",
duration3:"Von 3 Stunden bis 6 Stunden",
distance3:"12 km hin und zurück oder 6 km in eine Richtung",
usefulInfo:"NÜTZLICHE INFORMATIONEN",
pricing:"PREISE",
booking:"BUCHUNG",
queries:"Für alle Anfragen/Reservierungen senden Sie ein ",
including:"Was ist enthalten?",
what:"Was mitbringen?",
safety:"Sicherheit",
meeting:"Treffpunkt",
weather:"Wetter",
swimsuit:"Badebekleidung, Handtuch, trockene Kleidung zum Anziehen, Sonnencreme und Mütze.",
weather2:"Wir beobachten die lokalen Wettervorhersagen genau und werden Sie darüber informieren, welches Wetter Sie erwarten können.​",
weather3:" Bei schlechtem Wetter werden wir versuchen, Ihr Abenteuer zu verschieben oder Ihnen alternative Standorte zu nennen.",
safety2:"Vorkenntnisse im SUP/Kajak sind wünschenswert, aber nicht unbedingt erforderlich. Julien wird Ihnen einen detaillierten Reiseplan und alle Dos and Don’ts für ein sicheres und angenehmes Erlebnis geben.",
safety3:"Vorkenntnisse im Kajakfahren und eine gute Fitness sind ein Muss. Julien stellt Ihnen eine detaillierte Reiseroute und alle Verhaltensregeln für ein sicheres und angenehmes Erlebnis zur Verfügung.",
pickupNo:"ohne Abholung oder Rückgabe zum Hafen",
pickupYes:"mit Abholung und Rücktransfer zum Hafen",
person:"pro person",
allEquipment:"- Sämtliche Ausrüstung – Kajak/SUP, Paddel, Schnorchelausrüstung, Sonnenschirm, Packsack und Sicherheitsweste, falls erforderlich",
allEquipment2:"- Sämtliche Ausrüstung – Kajak, Paddel, Packsack und Sicherheitsweste, falls erforderlich",
arranged:"- Abholung und Rückgabe können arrangiert werden",
pickUp:"- Abholung und Rückgabe vom Hafen Brbinj/Bozava",
lunch:"- Lunchpaket mit 750 ml Wasser pro Person",
morePack:"mehr für Lunchpaket",
meeting1:"Strand von Veli Zal, wir können Sie aber auch abholen oder an anderen Orten treffen",
meeting2:"Dragove Bay, aber Abholung und Rücktransfer können arrangiert werden",
lunch2:"- Lunchpaket mit 750 ml Wasser pro Person für 2 Tage",
breakfast:"- Frühstück und Kaffee am zweiten Tag",
tent:"mit Zeltoption",
between:"Zwischen",
studio:"für Studio-Apartment mit Swimmingpool basierend auf der Jahreszeit",
text1:"Wir vermieten täglich sowohl Ozean- als auch Kristallkajaks auf Veli Zal und Saharun Strand. Wir haben mehrere Tarife für Stunden- und Tagesmieten. Rufen Sie uns an unter 0957116161 oder 0977145168. Alle unsere Preise gelten pro Kajak (nur Zweisitzer).",
trail1Text:"Wenn Sie Glück haben, sehen Sie unterwegs vielleicht sogar ein Rudel Delfine. Die Golubinka-Höhle ist eine sehr beliebte Meereshöhle, die jedes Jahr von vielen Menschen besucht wird. Eine Möglichkeit ist die Rückfahrt oder wir holen Sie ab. Dies ist eine Tagesmiete und die gesamte Ausrüstung muss bis 18:00 Uhr zurückgegeben werden",
trail2Text:"Beginnen Sie Ihre Reise in der Bucht des Dorfes Dragove an der Ostküste von Dugi Otok und fahren Sie nach Norden, um nicht nur die Küste, sondern auch die beiden Bunker zu besichtigen, die während der Tito-Ära im ehemaligen Jugoslawien gebaut wurden. Es wurde speziell zum Verstecken von Abfangschiffen gebaut und bietet auch einige Strände, einsame Olivenhaine und kleine Inseln. Sie können sogar in Bozava anhalten und in einem der örtlichen Restaurants zu Mittag essen. Es handelt sich um eine Tagesmiete und die gesamte Ausrüstung muss bis 18:00 Uhr zurückgebracht werden",
trail3Text:"Reisen Sie vom Strand Veli Zal nach Saharun, einem der berühmtesten Strände Kroatiens. Unterwegs finden Sie entlang der felsigen Küste viele Orte zum Anhalten und Schnorcheln, darunter einen der berühmtesten Tauchplätze von Dugi Otok in Lopata. Dieser Weg wird für Besucher attraktiv sein, die für einen Tagesausflug aus der Stadt kommen. Nutzen Sie die Gelegenheit und besuchen Sie zwei der schönsten Strände der Insel. Es handelt sich um eine Tagesmiete und die gesamte Ausrüstung muss bis 18:00 Uhr zurückgegeben werden",
trail4Text:"Abenteuerlustige können die beiden begehrtesten Attraktionen von Dugi Otok mit den schönsten Stränden und einem großen Teil der Küste kombinieren. Sie haben die Möglichkeit, in der Natur an einem dafür vorgesehenen Ort zu schlafen, mit einem bereitgestellten Zelt und der gesamten notwendigen Ausrüstung, oder in unserer Unterkunft im Dorf Dragove (je nach Verfügbarkeit).",
day1:"Tag 1",
day2:"Tag 2",
day1Text:"Abfahrt von Veli Rat und Fahrt zum Wrack der St. Michelle. Fahren Sie dann nach Süden zum Saharun Beach, vorbei an der atemberaubenden Landschaft hoch aufragender Klippen sowie dem sehr beliebten Tauchplatz Lopata. Anschließend müssen Sie noch 6 km bis zu Ihrem Tagesendpunkt in der Nähe des Strandes Veli Žal zurücklegen. Ruheplatz für die Nacht nach Vereinbarung vor der Abreise.",
day2Text:"Fahren Sie zur von Ihnen gewählten Zeit Richtung Süden zur Golubinka-Höhle. Fahren Sie an zwei der schönsten Strände vorbei, die nur über das Meer erreichbar sind, und betreten Sie die Höhle gegen 13:00 Uhr. Sie sind dann nur noch wenige Schritte von Ihrem Abholpunkt und dem Ende der Reise entfernt.",
tours:"EINTÄGIGE TOUREN",
text2:" Die beliebtesten Küstenattraktionen liegen im Nordwesten der Insel. Ob auf oder unter der Wasseroberfläche. Für viele ist es am besten, einen Führer zu haben, aber für diejenigen unter Ihnen, die die Gegend auf eigene Faust erkunden möchten, ist dies Ihre Eintrittskarte in die Freiheit. Wir bieten mehrere Routen mit unterschiedlichen Schwierigkeitsgraden an",
adventure:"Das Abenteuer beginnt hier...",
text3:"Titos Bunker: Beginnen Sie Ihre Reise in der Bucht des Dorfes Dragove an der Ostküste von Dugi otok und fahren Sie nach Norden, um nicht nur die Küste, sondern auch die drei Bunker zu besuchen, die während der Tito-Ära im ehemaligen Jugoslawien gebaut wurden. Speziell gebaut, um abgefangene Schiffe zu verstecken. Sie werden viele Strände und abgelegene Olivenhaine finden und Sie können sogar in Bozava in einem der verschiedenen lokalen Restaurants zu Mittag essen. 50 Euro",
twoDays:"ZWEITÄGIGE TOUR",
text4:"Die blaue Lagune: Reisen Sie vom Strand Veli Zal nach Saharun, einem der berühmtesten Strände Kroatiens. Unterwegs finden Sie viele Orte entlang der felsigen Küste, an denen Sie anhalten und schnorcheln können, darunter einer der berühmtesten Tauchplätze von Dugi otok in Lopata. 50 Euro",
twoDays2:"Touren inklusive Unterkunft",
trail1: "Vom Strand Veli Zal zur Golubinka-Höhle",
trail2: "Kristallerlebnis",
trail3:"Der Weg der Lagune",
trail4:"Veli Rat (Leuchtturm) zur Golubinka-Höhle",
text5:"Der Sonnenuntergang: Fahren Sie vom Strand Veli Zal ab und fahren Sie bis zur Nordspitze der Insel nach Veli Rat und seinem berühmten Leuchtturm. Dort können Sie den schönsten Sonnenuntergang an der Adria genießen, um einen fantastischen Tag auf dem Meer ausklingen zu lassen. Die Abholung ist für diesen Trail aufgrund seiner Länge und des Timings inbegriffen. 80 Euro",
note:"BITTE BEACHTEN SIE:",
text6:"Bei Option 1, 2 und 3 ist es möglich, nur eine Strecke zu fahren und zu einem vereinbarten Zeitpunkt gegen Aufpreis abgeholt zu werden.",
text7:" * Alle Aktivitäten sind wetterabhängig und beginnen am besten morgens",
bike:"Fahrradverleih",
daily:"Tagesmieten",
text8:" Wir haben traditionelle Fahrräder zu mieten. Diese können an unserem Standort im Dorf Dragove abgeholt werden, oder je nachdem, wo Sie sich befinden, können wir sie zu Ihnen bringen. Unser Standardpreis beträgt 10 Euro pro Tag, jedoch für keine Anmietungen von",
text9:"ab vier tagen sinkt der preis auf 8 euro pro tag. Rufen Sie uns an unter 0957116161 oder 09777145168.",
sunset:"Sonnenuntergang auf der Insel Mezanj",
enjoy:"Genießen Sie einen wunderschönen Sonnenuntergang auf Veli Zal",
text10:"Die Insel Mezanj liegt direkt gegenüber dem Strand von Veli Zal, unserer Operationsbasis. Warum nicht ein Kajak oder ein SUP-Board mieten und einen Platz entweder auf Mezanj oder auf den umliegenden Gewässern finden. Alle Abfahrten sollten mindestens 45 Minuten vor Sonnenuntergang erfolgen. Wie bei den meisten Wasseraktivitäten ist dies wetterabhängig",
glide:"Mieten Sie einen Elektroroller",

text11:"Erkunden Sie die Insel mit minimalen Auswirkungen auf die Umwelt. Wir vermieten Elektroroller für 2 Personen, Helme inklusive, bei Bedarf bieten wir auch ein Solarladegerät für längere Fahrten an.",
lost:"Verloren im Meer",
diver:"Mieten Sie einen Taucher für Ihre verlorenen Sachen",
diver2:"Haben Sie etwas Wertvolles verloren? Haben Sie einen Standort und ist er in Küstennähe? Julien ist ein erfahrener Taucher mit einem hohen Fundanteil.",
sup:"SUP-Vermietung",
text12:"Neben Kajaks vermieten wir auch SUP-Boards. Die Preise gelten auch für Stunden- und Tagessätze, für weitere Informationen rufen Sie uns unter 0957116161 oder 09577145168 an. Die Preise gelten pro SUP-Boards.",
transfers1:"Transfers von Brbinj nach Veli Zal",
more:"Mehr sehen...",
transfers2:"Transfers von Brbinj nach Saharun",
transfers3:"Transfers von Brbinj nach Veli Rat",
text13:"Veli Zal ist der ultimative, nicht kommerzialisierte Strand und sehr zu empfehlen für diejenigen, die sich wieder mit der unberührten Küste verbinden möchten. Wir bieten private Transfers von Brbinj an und das ist unsere beliebteste Route. Veli Zal ist auf der Insel sehr berühmt und verfügt über weißen Sand im Meer und schöne weiße Kiesel draußen. Wir vermieten auch Liegestühle und Sonnenschirme, damit Sie Ihren Tag am Strand bequem genießen können. Unsere transparenten Kanus und Meereskajaks sind da, wenn Sie sich abenteuerlustig genug für eine unserer vielen Safaris fühlen oder wenn Sie sich einfach vom Strand lösen und zur kleinen Insel namens Mezanj direkt gegenüber fahren möchten. Das kristallklare Wasser macht Lust auf Schnorcheln und das ist die perfekte Gelegenheit, unsere kristallklaren Kanus zu mieten, da Sie paddeln und auf den wunderschönen Meeresboden starren können. Wir vermieten auch Aquaglide-Ausrüstung für windige Tage, und die nahe gelegene Strandbar bietet Strandbesuchern den perfekten Ort, um einen Happen und ein Getränk zu genießen.",
top:"ZURÜCK NACH OBEN",
text14:" Veli Rat hat wohl den besten Campingplatz auf Dugi otok und ist der nächstgelegene Ausgangspunkt für diejenigen, die um das berühmte Schiffswrack von St. Michelle herum schnorcheln möchten. Veli Rat ist auch berühmt für seinen Leuchtturm, den höchsten an der Adria, und es ist wirklich einen Besuch wert. Fügen Sie die beiden sehr süßen Kiesstrände neben dem Leuchtturm und den Ruf des Ortes als der beste Sonnenuntergang auf dieser Seite Italiens hinzu, und Sie haben die besten Gründe der Welt, ihn zu besuchen.",
transfers4:"Bozava oder Brbinj zum Strand Saharun",
transfers5:"Brbinj nach Veli Rat",
transfers6:"Brbinj nach Veli Zal",
saharun:" Saharun ist bei weitem das begehrteste Ziel auf Dugi otok. Er hat sich einen Ruf als einer der schönsten Strände Kroatiens erworben. Es ist insofern einzigartig, als es flach und sandig ist. Eingebettet in eine wunderschöne Bucht, ist Saharun wirklich ein Traum für Paare!",
accommodation: "Unterkunft",
acc1:"Wir bieten Apartments mit beheiztem Pool und Whirlpool mit wunderschönem Blick auf das Meer",
acc2:"Sonderangebote für alle Besucher im April, Mai, Juni, September und Oktober",
acc3:"Rabatte verfügbar, wenn Sie uns eine E-Mail senden! Überprüfen Sie den Link, um zu sehen",
acc4:"Wohnungen!"

        }
      },
      fr: {
        translation: {
          options:"De nombreuses possibilités d'explorer Dugi Otok",
          enquiry: "Faire une demande de renseignements",
          routes:"Nous avons de nombreuses routes personnalisées",
          email2:"Envoyer un courriel",
          form: "Formulaire de contact",
          name:"Nom",
          title:"Titre",
          email:"Email",
          message:"Votre message",
          soon:"Nous vous répondrons dans les plus brefs délais !",
          send: "Envoyer un courriel",
          send2: "Envoyer",
          emailSent:"Votre courriel a été envoyé. Merci !",
          home:"Page d'accueil",
          golubinka: "Golubinka cave",
          boat:"Excursions en bateau",
          activities: "Activités",
          transfers:"Transferts",
          contact:"Contact",
          gallery: "Gallerie",
kayak: "Location de kayaks",
kayak2:"Tour autoguidé",
duration:"DURÉE",
distance:"DISTANCE",
difficulty:"DIFFICULTÉ",
departure:"DÉPART",
departure1:"De 8h30 à 11h",
easyMod:"Facile/Modéré",
easy:"Facile",
hard:"Modéré/Difficile",
distance4:"21 kilomètres",
duration4:"2 Jours",
duration1:"Environ de 2 heures à 10 heures",
distance1:"14 km aller-retour ou 7 km aller simple",
duration2:"De 2 heures à 8 heures",
distance2:"Environ 10 kilomètres",
duration3:"De 3 heures à 6 heures",
distance3:"12 km aller-retour ou 6 km aller simple",
usefulInfo:"INFOS UTILES",
pricing:"PRIX",
booking:"RÉSERVATION",
queries:"Pour toutes demandes/réservations envoyer un ",
including:"Qu'est-ce qui est inclus?",
what:"Qu'apporter?",
safety:"Sécurité",
meeting:"Point de rendez-vous",
weather:"Météo",
swimsuit:"Maillot de bain, serviette, vêtements secs pour se changer, crème solaire et chapeau.",
weather2:"Nous surveillons de près les prévisions météorologiques locales et vous informerons du type de temps auquel vous pouvez vous attendre.​",
weather3:" En cas de mauvais temps, nous essaierons de reprogrammer votre aventure ou de vous proposer des emplacements alternatifs.",
safety2:"Une expérience préalable en SUP/Kayak est souhaitable mais pas indispensable. Julien vous fournira un itinéraire détaillé et toutes les choses à faire et à ne pas faire pour une expérience sûre et agréable.",
safety3:"Une expérience préalable en kayak et une bonne condition physique sont indispensables. Julien vous fournira un itinéraire détaillé ainsi que toutes les choses à faire et à ne pas faire pour une expérience sûre et agréable.",
pickupNo:"sans prise en charge ni dépose au port",
pickupYes:"avec prise en charge et retour au port",
person:"par personne",
allEquipment:"- Tout l'équipement - Kayak/SUP, pagaie, équipement de plongée en apnée, parasol, sac étanche et gilet de sécurité si nécessaire",
allEquipment2:"- Tout l'équipement - Kayak, pagaie, sac étanche et gilet de sécurité si nécessaire",
arranged:"- La prise en charge et le retour peuvent être organisés",
pickUp:"- Prise en charge et retour au port de Brbinj/Bozava",
lunch:"- Pack déjeuner avec 750 ml d'eau par personne",
morePack:"plus pour le panier-repas",
meeting1:"Plage Veli zal, mais nous pouvons aussi venir vous chercher ainsi que vous rencontrer à d'autres endroits",
meeting2:"Dragove bay mais prise en charge et retour peuvent être organisés",
lunch2:"- Pack déjeuner avec 750 ml d'eau par personne pendant 2 jours",
breakfast:"- Petit-déjeuner et café le jour 2",
tent:"avec option tente",
between:"Entre",
studio:"pour studio avec piscine en fonction de la période de l'année",
text1:"Nous louons tous les jours des kayaks de mer et des kayaks transparents à Veli Zal et la plage de Saharaun. Nous avons plusieurs tarifs pour les locations à l'heure et à la journée. Appelez-nous au 0957116161 ou 0977145168. Tous nos prix sont par kayak (uniquement pour des kayaks à deux places).",
trail1Text:"De Veli Žal, dirigez-vous vers le sud jusqu'à la grotte de Golubinka. Avec 4 plages privées le long du chemin, vous disposerez de nombreux endroits pour vous arrêter, bronzer et vous détendre. Si vous êtes chanceux, vous pourrez même apercevoir une meute de dauphins en cours de route. La grotte de Golubinka est une grotte marine très populaire qui est visitée par de nombreuses personnes chaque année. Une option est de faire un aller-retour ou nous pouvons venir vous chercher. Il s'agit d'une location à la journée et tout le matériel doit être restitué avant 18 heures.",
trail2Text:"Commencez votre voyage depuis la baie du village de Dragove, sur la côte est de Dugi Otok, et dirigez-vous vers le nord pour visiter non seulement le littoral, mais également les deux bunkers construits pendant l'ère Tito de l'ex-Yougoslavie. Construit spécialement pour cacher les navires d'interception, vous trouverez également quelques plages, des oliveraies isolées, des petites îles et vous pourrez même vous arrêter à Bozava pour déjeuner dans l'un des restaurants locaux. Il s'agit d'une location à la journée et tout l'équipement doit être rendu avant 18 heures.",
trail3Text:"Voyagez de la plage de Veli Zal à Saharun, l'une des plages les plus célèbres de Croatie. Sur le chemin, vous trouverez de nombreux endroits le long de la côte rocheuse où vous pourrez vous arrêter et faire de la plongée avec tuba, notamment l'un des sites de plongée les plus célèbres de Dugi Otok, à Lopata. Ce sentier séduira les visiteurs venant de la ville pour une excursion d'une journée. Profitez-en pour visiter deux des plus belles plages de l’île. Il s'agit d'une location à la journée et tout l'équipement doit être rendu avant 18h00.",
trail4Text:"Pour les plus aventureux, pourquoi ne pas combiner les deux attractions les plus recherchées de Dugi Otok, ainsi que ses plus belles plages et une grande partie de son littoral. Vous aurez la possibilité de dormir dans la nature dans un endroit désigné, avec une tente fournie et tout l'équipement nécessaire, ou dans notre hébergement au village de Dragove (selon disponibilité).",
day1:"Jour 1",
day2:"Jour 2",
day1Text:"Départ de Veli Rat et voyage vers l'épave de St Michelle. Ensuite, dirigez-vous vers le sud jusqu'à la plage de Saharun en passant devant un paysage époustouflant de falaises imposantes ainsi que le site de plongée très populaire de Lopata. Ensuite, vous devrez parcourir encore 6 km jusqu'à votre point final de la journée, près de la plage de Veli Žal. Lieu de repos pour la nuit à convenir avant le départ.",
day2Text:"Dirigez-vous vers le sud jusqu'à la grotte de Golubinka à l'heure de votre choix. Passez par deux des plus belles plages accessibles uniquement par la mer et entrez dans la grotte vers 13 heures. Vous n'êtes alors qu'à une courte distance de votre point de prise en charge et de la fin du voyage.",
tours:"VISITES D'UNE JOURNÉE",
text2:" Les attractions côtières les plus populaires se trouvent au nord-ouest de l'île. Que ce soit sur ou sous la surface de l'eau. Pour beaucoup, il est préférable d'avoir un guide, mais pour ceux d'entre vous qui souhaitent explorer par eux-mêmes, c'est votre billet pour la liberté. Nous proposons plusieurs itinéraires avec différents niveaux de difficulté",
adventure:"L'aventure commence ici...",
text3:"Le chemin des bunkers : Commencez votre voyage depuis la baie du village de Dragove, sur la côte est de Dugi otok, et dirigez-vous vers le nord pour visiter, non seulement le littoral, mais aussi les trois bunkers construits pendant l'ère Tito de l'ex-Yougoslavie. Construits dans le but de cacher les navires d'interception. Vous trouverez de nombreuses plages, des oliveraies isolées et vous pourrez même vous arrêter à Bozava pour déjeuner, dans l'un des différents restaurants locaux.50 euros",
twoDays:"VISITE DE DEUX JOURS",
text4:"Le chemin des lagons : Voyagez de la plage de Veli Zal à Saharun, l'une des plages les plus célèbres de Croatie. En chemin, vous trouverez de nombreux endroits le long de la côte rocheuse pour vous arrêter et faire du snorkeling, notamment l'un des sites de plongée les plus célèbres de Dugi otok, à Lopata. 50 euros",
twoDays2:"Circuits comprenant l'hébergement",
trail1: "De la plage de Veli Zal à la grotte de Golubinka",
trail2: "Expérience Cristal",
trail3:"Le sentier de la Lagune",
trail4:"Veli Rat (phare) à la grotte de Golubinka",
text5:"Le chemin du coucher du soleil : Partez de la plage de Veli Zal et remontez jusqu'à la pointe nord de l'île, jusqu'à Veli Rat et son célèbre phare, où vous pourrez admirer le plus beau coucher de soleil de l'Adriatique, pour conclure une fantastique journée en mer. Le transport retour est inclus pour ce parcours en raison de sa longueur et de son timing. 80 euros",
note:"VEUILLEZ NOTER:",
text6:"Il est possible de ne faire qu'un aller simple pour les randonnées 1, 2 et 3 et d'obtenir un transfert à une heure convenue pour un coût supplémentaire.",
text7:" * Toutes les activités dépendent de la météo et doivent être de preference commencées le matin.",
bike:"Location de vélos",
daily:"Locations journalières",
text8:" Nous avons des vélos traditionnels disponibles à la location. Ceux-ci peuvent être récupérés à notre domicile dans le village de Dragove, ou selon l'endroit où vous vous trouvez, nous pouvons vous les apporter. Notre tarif standard est de 10 euros par jour, mais pour toutes les locations de ",
text9:"quatre jours ou plus, le tarif tombe à 8 euros par jour. Appelez-nous au 0957116161 ou au 09777145168.",
sunset:"Coucher de soleil sur l'île de Mezanj",
enjoy:"Profitez d'un magnifique coucher de soleil sur Veli Zal",
text10:"L'île de Mezanj se trouve juste en face de la plage de Veli Zal, notre base d'opérations. Pourquoi ne pas louer un kayak ou une planche SUP et trouver un spot soit sur Mezanj soit sur les eaux environnantes. Tous les départs doivent être effectués au moins 45 minutes avant le coucher du soleil. Comme pour la plupart des activités nautiques, ceci dépend de la météo.",
glide:"Louer un scooter électrique",

text11:"Explorez l'île avec un impact minimal sur l'environnement. Nous louons des scooters électriques pour 2 personnes, casques inclus, et si besoin nous proposons également un chargeur solaire pour les trajets plus longs.",
lost:"Perdu dans la mer",
diver:"Louez un plongeur pour vos biens perdues",
diver2:"Avez-vous perdu un objet de valeur ? Vous avez un emplacement et il se trouve près de la côte ? Julien est un plongeur expérimenté avec un taux de récupération élevé.",
sup:"Locations Sup",
text12:"En plus des kayaks, nous louons également des planches de SUP. Les prix sont également pour les tarifs horaires et journaliers, pour plus d'informations, appelez-nous au 0957116161 ou 09577145168. Les tarifs sont par planche de SUP.",
transfers1:"Transferts de Brbinj à Veli Zal",
more:"En savoir plus...",
transfers2:"Transferts de Brbinj à Saharun",
transfers3:"Transferts de Brbinj à Veli Rat",
text13:"Veli Zal est la plage non commercialisée par excellence, et est fortement recommandée pour ceux qui veulent renouer avec un véritable bord de mer préservé. Nous proposons des transferts depuis Brbinj et c'est notre itinéraire le plus demandé. Veli Zal est très célèbre sur l'île et présente du sable blanc dans la mer et de beaux galets blancs à l'extérieur. Nous louons également des chaises longues et des parasols pour que vous soyez à l'aise pour profiter de votre journée à la plage. Nos canoës transparents et nos kayaks de mer sont là si vous vous sentez assez aventureux pour l'un de nos nombreux safaris, ou si vous voulez simplement vous éloigner de la plage, et vous rendre sur la petite île appelée Mezanj juste en face. L'eau cristalline vous donnera envie de faire de la plongée libre et c'est l'occasion idéale de louer nos canoës en cristal, car vous pourrez pagayer et contempler les magnifiques fonds marins. Nous louons également des équipements d'aquaglide pour les jours de vent, et le bar de la plage, situé à proximité, est l'endroit idéal pour manger et boire un verre.",
top:"RETOUR EN HAUT",
text14:" Veli Rat possède sans doute le meilleur camping de Dugi otok, et c'est le point de départ le plus proche, pour ceux qui souhaitent aller faire de la plongée avec tuba autour de la célèbre épave du St Michelle. Veli Rat est également célèbre pour son phare, le plus haut de l'Adriatique, qui vaut vraiment le détour. Ajoutez à cela les deux très jolies plages de galets à côté du phare et la réputation du lieu comme étant le meilleur coucher de soleil de ce côté de l'Italie, et vous avez toutes les meilleures raisons du monde de venir le visiter.",
transfers4:"De Bozava ou Brbinj à la plage de Saharun",
transfers5:"De Brbinj à Veli Rat",
transfers6:"De Brbinj à Veli Zal",
saharun:" Saharun est de loin la destination la plus recherchée de Dugi otok. Elle a acquis la réputation d'être l'une des plus belles plages de Croatie. Elle est unique en ce sens qu'elle est peu profonde et sablonneuse. Nichée dans une magnifique baie, Saharun est vraiment le paradis des couples, mais attention il y a foule !",
accommodation: "Hébergement",
acc1:"Nous proposons des appartements avec piscine chauffée et jacuzzi avec une belle vue sur la mer",
acc2:"Offres spéciales pour tous les visiteurs en avril, mai, juin, septembre et octobre",
acc3:"Réductions disponibles si vous nous envoyez un e-mail ! Consultez le lien pour voir",
acc4:"Appartements!"

        }
      },
    }
  });

export default i18n;